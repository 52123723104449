import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const classes = {
  title: 'text-lg font-bold',
  link: 'underline',
};

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" />
    <h1 className={classes.title}>404: Not Found</h1>
    <p>
      Vous venez d'arriver sur une page qui n'existe pas.{' '}
      <Link className={classes.link} to="/">
        Retourner à la page d'accueil.
      </Link>
      .
    </p>
  </Layout>
);

export default NotFoundPage;
